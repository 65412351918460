<template>
  <div class="aspect-[16/9] pt-[58px] minimal-scrollbar" v-if="siteOptions">
    <slot />
  </div>

  <aside 
  class="mt-[120px] bg-white flex-none w-[230px] h-screen border-l"
  v-if="routeCollection.length"
  >
    <div class="w-full h-full overflow-y-auto px-2 no-scrollbar">
      <div class="flex flex-col">
        <template v-for="(item, index) in routeCollection">
          <div 
          :class="[ 
            item.rudiments.slug === route.params.slug ? 'bg-zinc-950 active-rudiment' : 'bg-gray-200',
            { 'mt-2' : index === 0 }
          ]"
          class="p-1 rounded w-full flex items-center justify-center mb-2">
            <NuxtLink :to="`/perform/rudiment/${item.rudiments.slug}`" class="p-2 w-full items-center justify-center">
              <img
              v-if="item.rudiments?.image"
              :src="'https://media.drumrudiments.app/' + item.rudiments.image.id + '/' + item.rudiments.image.filename_download"
              :height="item.rudiments.image.height"
              :width="item.rudiments.image.width"
              :alt="item.rudiments.title"
              classes="rounded-xl"
              />
              <h2 
              :class="[ 
                'text-lg font-semibold sm:text-sm/5 mt-2',
                item.rudiments.slug === route.params.slug ? 'text-white' : 'text-zinc-950',
                { 'mt-2' : index === 0 }
              ]"
              >
                {{ item.rudiments.title }}
              </h2>
            </NuxtLink>
          </div>
        </template>
      </div>
    </div>
  </aside>
</template>

<script setup>
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const optionsStore = useOptionsStore()
const messageStore = useMessageStore()
const subStore = useSubStore()
const route = useRoute()
const config = useRuntimeConfig()

const routeCollection = ref([])
const collectionTitle = ref(null)
const collectionTitleSlug = ref(null)

const { fetchProfile, fetchNotifications } = useUser()

const siteOptions = ref(null)

if (!subStore.utmSource) {
  // tracking
  let utmSource = route?.query?.utm_source || null
  let utmMedium = route?.query?.utm_medium || null
  let utmCampaign = route?.query?.utm_campaign || null
  let utmLandingPage = route?.path || null
  if (utmMedium === 'cpc') utmMedium = 'paid'
  if (!utmSource) {
    utmSource = document?.referrer || null
  }
  subStore.addUtm(utmSource, utmMedium, utmCampaign, utmLandingPage)
}

setTimeout(() => {
  messageStore.clearMessage()
}, 3000)

const { data: options, error } = await useAsyncData(
  'site-options', 
  async () => {
    const { data } = await supabase.from('site_options').select('*').maybeSingle()
    return data
  }
)

if (!options.value) {
  throw createError({
    statusCode: 404
  })
}

const checkRoute = async () => {
  
  const slug = route.params?.slug
  if (!slug) {
    return
  }
 
  const { data: collection } = await supabase
  .from('rudiments')
  .select(`
    id,
    title,
    slug,
    status,
    collections_rudiments(
      collections(
        title,
        slug
      )
    )
  `)
  .eq('slug', slug)
  .in('status', config.public.postStatus.split(','))
  .maybeSingle()
   
  if (!collection) {
    return
  }

  const collectionSlug = collection?.collections_rudiments[0]?.collections.slug || null

  if (!collectionSlug) {
    return
  }

  const { data: collectionRudiments } = await supabase
  .from('collections')
  .select(`
    title,
    slug,
    collections_rudiments(
      rudiments_sort,
      rudiments(
        title, 
        slug,
        image(
          id,
          filename_download,
          width,
          height
        ),
        rudiments_rudiment_categories(
          rudiment_categories(
            title,
            slug
          )
        )  
      )
    )
  `)
  .eq('slug', collectionSlug)
  .order('rudiments_sort', { referencedTable: 'collections_rudiments', ascending: true })
  .maybeSingle()

  if (!collectionRudiments) {
    return
  }

  collectionTitle.value = collectionRudiments?.title || null
  collectionTitleSlug.value = collectionRudiments?.slug || null
  routeCollection.value = collectionRudiments?.collections_rudiments
  
}

checkRoute()

siteOptions.value = options.value

optionsStore.updateOptions(options.value)

watch(user, async () => {
  if (user.value) {
    await fetchProfile()
  }
})

</script>